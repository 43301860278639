<template>
	<div>
		<div class="person_infor">
			<div class="login_top">
				<div class="img"> <img src="@/assets/login/flag.png"></div>
				<div class="login_title">{{$t('title.helpCenter')}}</div>
			</div>
		</div>
		<div class="infor_list">
			<div class="infor_item">
				<div class="infor_item_title">{{$t('other.commonProblem')}}</div>
				<div class="infor_item_list" v-for="(item,index) in helpCenterList" :key="index"
					@click="checkDetail(item)">
					{{item.title}}
				</div>
			</div>
		</div>


		<!-- 详情 -->
		<el-dialog title="" :visible.sync="dialogVisible" width="38%">
			<div slot="title">
				<div class="title_all">
					<div class="title_img"><img src="@/assets/login/flag.png"></div>
					<div>{{detail.title}}</div>
				</div>
			</div>
			<div class="content">
				<div v-html="detail.body" class="body"></div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: false,

				helpCenterList: [],
				detail: '',
			}
		},
		mounted() {
			this.getHelpCenter();
		},
		methods: {
			getHelpCenter() {
				let data = {
					category_id: 25,
					type: 2,
					is_recommend: 0,
					page: 1,
					pageSize: 10000,
				}
				this.$http.helpCenter(data).then(res => {
					if (res.code == 1) {
						this.helpCenterList = res.data.data;
					} else {
						this.$message.info(res.msg);
					}
				})
			},
			checkDetail(e) {
				this.dialogVisible = true;
				this.$http.helpCenterDetail({
					id: e.id
				}).then(res => {
					if (res.code == 1) {
						this.detail = res.data;
					} else {
						this.$message.info(res.msg)
					}
				})
			},
		}
	}
</script>

<style scoped="" lang="less">
	.person_infor {
		width: 926px;
	}

	.login_top {
		display: flex;
		padding: 24px;
		border-bottom: 1px solid #F3F5F6;

		.img {
			display: flex;
			align-items: center;

			img {
				width: 18px;
				height: 18px;
			}
		}

		.login_title {
			margin-left: 8px;
			font-weight: bold;
			font-size: 18px;
		}
	}

	.infor_list {
		text-align: left;
		padding: 0 32px;
		font-size: 18px;

		.infor_item {
			padding: 32px 0 40px;
			border-bottom: 1px dashed #DDDDDD;

			&:last-child {
				border-bottom: none;
			}

			.infor_item_title {
				color: #333333;
				font-weight: bold;
				margin-bottom: 32px;
			}

			.infor_item_list {
				margin-bottom: 24px;
				color: #333333;
				max-width: 862px;

				&:hover {
					cursor: pointer;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.title_all {
		display: flex;
		align-items: center;
		font-size: 20px;
		font-weight: bold;

		.title_img {
			margin-right: 8px;

			img {
				display: flex;
				align-items: center;
				width: 24px;
				height: 24px;
			}
		}

		.content {
			font-size: 14px;


		}
	}


	/deep/ .el-dialog__body {
		max-height: 436px;
		overflow-y: scroll;
	}

	.body {
		text-align: left;
	}
</style>
